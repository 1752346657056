<template>
  <div class="nav-com shadow">
    <div class="nav-com-content">
      <div class="nav-com-box" @click="$router.push('/ori-cucenter/home')">
        <i class="el-icon-news"></i>
        首页
      </div>

      <div class="nav-com-box">
        <span @click="$router.push('/ori-cucenter/basic-form')">
          <i class="el-icon-chat-line-square"></i>
          基本信息
        </span>

        <div class="more-nav">
          <div
            class="nav-item"
            @click="$router.push('/ori-cucenter/basic-form')"
          >
            基本信息
          </div>
          <div class="nav-item" @click="$router.push('/ori-cucenter/security')">
            安全中心
          </div>
          <div class="nav-item" @click="$router.push('/ori-cucenter/invite')">
             邀请好友
          </div>
          <div class="nav-item" @click="$router.push('/ori-cucenter/history')">
           浏览记录
          </div>
          <div class="nav-item" @click="$router.push('/ori-cucenter/news')">
            我的消息
          </div>
          <div class="nav-item" @click="$router.push('/ori-cucenter/coupon')">
            优惠券
          </div>
        </div>
      </div>

      <div class="nav-com-box" @click="$router.push('/ori-cucenter/apply')">
        <i class="el-icon-document-copy"></i> 我的报名
      </div>

      <div class="nav-com-box" @click="$router.push('/ori-cucenter/project')">
        <i class="el-icon-takeaway-box"></i>
        我的项目
      </div>

      <div class="nav-com-box" @click="$router.push('/ori-cucenter/resume')">
        <i class="el-icon-document-remove"></i> 我的简历
      </div>

      <div class="nav-com-box">
        <span @click="$router.push('/ori-cucenter/demand')">
          <i class="el-icon-files"></i>
          需求中心
        </span>

        <div class="more-nav">
          <div class="nav-item" @click="$router.push('/ori-cucenter/demand')">
            我的需求
          </div>
          <div
            class="nav-item"
            @click="$router.push('/ori-cucenter/demand-delivery')"
          >
            投递需求
          </div>
          <div
            class="nav-item"
            @click="$router.push('/ori-cucenter/demand-proxy')"
          >
            代发需求
          </div>
          <div
            class="nav-item"
            @click="$router.push('/ori-cucenter/demand-talent')"
          >
            人才需求
          </div>
        </div>
      </div>

      <div class="nav-com-box">
        <span @click="$router.push('/ori-cucenter/inviting?type=info')">
          <i class="el-icon-bank-card"></i>
          创业招商
        </span>

        <div class="more-nav">
          <div
            class="nav-item"
            @click="$router.push('/ori-cucenter/inviting?type=info')"
          >
            招商介绍
          </div>
          <div
            class="nav-item"
            @click="$router.push('/ori-cucenter/inviting?type=price')"
          >
            服务详情
          </div>
          <div
            class="nav-item"
            @click="$router.push('/ori-cucenter/inviting?type=create')"
          >
            创建项目
          </div>
          <div
            class="nav-item"
            @click="$router.push('/ori-cucenter/inviting?type=work')"
          >
            合作信息
          </div>

          <div
            class="nav-item"
            @click="$router.push('/ori-cucenter/inviting?type=bill')"
          >
            招商订单
          </div>
        </div>
      </div>

      <div class="nav-com-box">
        <span @click="$router.push('/ori-cucenter/order')">
          <i class="el-icon-sunset"></i>
          我的预约
        </span>
      </div>
    </div>
    <div class="logo-wrap">
      <div class="img">
        <img :src="require('@/assets/images/in-peak.png')" alt="" srcset="" />
      </div>
      <div class="desc">
        <div class="title">应必客</div>
        <div class="url">in-peak.com</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nav-com",
};
</script>

<style lang="scss" scoped>
.nav-com {
  position: fixed;
  left: 6%;
  top: 100px;
  z-index: 999;

  background: #fafafa;
  border-radius: 20px;

  .logo-wrap {
    display: flex;
    padding: 20px;

    .img {
      margin-right: 10px;
      width: 60px;
      img {
        width: 60px;
      }
    }

    .desc {
      text-align: center;
      .title {
        font-size: 24px;
      }
    }
  }

  .nav-com-content {
    padding: 10px;

    .nav-com-box {
      position: relative;

      margin-top: 20px;

      width: 180px;

      cursor: pointer;
      padding: 8px 15px;

      border-radius: 30px;

      transition: all 0.2s ease-in;

      &:hover,
      &:active,
      &.active {
        background: #999;
        color: #fff;

        .more-nav {
          height: auto;
          width: 50%;
          border-width: 1px;
        }
      }

      i {
        margin-right: 4px;
        // font-weight: bold;
      }

      .more-nav {
        font-size: 14px;
        z-index: 99;
        background: #fff;
        position: absolute;
        top: 100%;
        left: 50%;
        width: 50%;
        height: 0;

        overflow: hidden;

        transition: all 0.15s ease-in;

        border-radius: 4px;
        border: 1px solid #ededed;
        border-width: 0;

        color: #333;

        .nav-item {
          display: inline-block;
          width: 100%;
          padding: 2px 10px;
          text-align: center;

          &:hover,
          &:active,
          &.active {
            background: #999;
            color: #fff;
          }
        }
      }

      .pull-down {
        z-index: 99;
        float: right;
      }
    }

    .collapse {
      width: 60px;
    }
  }
}
</style>