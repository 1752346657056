<template>
  <div class="cu-center">
    <ori-header />

    <div class="container">
      <div class="w1200 content">
        <!-- <div class="left">
          <side-bar />
        </div> -->
        <div class="right">
          <router-view />
        </div>
      </div>
    </div>

    <Nav />

    <page-foot />
  </div>
</template>

<script>
import PageFoot from "../../../components/ori-com/PageFoot.vue";
import Nav from "./Nav.vue";
// import SideBar from "./SideBar.vue";
export default {
  components: {
    PageFoot,
    Nav,
    // SideBar
  },
};
</script>

<style lang="scss" scoped>
.cu-center {
  width: 100%;
  background-color: #f9f9f9;

  .content {
    display: flex;
    align-items: flex-start;

    padding: 20px 0px 50px 0px;

    .left {
      flex: 0 0 auto;
      width: 160px;
      min-height: 700px;
      background-color: #fff;
      margin-right: 15px;

      ::v-deep .el-submenu {
        .el-menu-item {
          min-width: 160px;
          &:hover,
          &:active,
          &.is-active {
            color: #fff;
            background-color: #e62d31;
          }
        }
      }

      ::v-deep .el-menu {
        border-right: none;
        .el-submenu__title,
        .el-menu-item {
          min-width: 160px;
          &:hover,
          &:active,
          &.is-active {
            color: #fff;
            background-color: #e62d31;
            i {
              color: #fff;
            }
          }
        }
      }
    }

    .right {
      flex: 1 1 auto;
    }
  }
}
</style>